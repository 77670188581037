import React, { useState } from "react";
import Link from "next/link";
import { Swiper, SwiperSlide } from "swiper/react";
import { FreeMode, Navigation, Thumbs, Autoplay } from "swiper";
import Image from "next/image";
import {
  hero_3_swiper_data,
} from "../../data/hero_3_swiper_data";
import CountdownTimer from "../countdowntimer";
import TypewriterPlaces from "../typewriter_places";

// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";

const Hero_3 = () => {

  return (
    <>
      {/* <!-- Hero Slider --> */}
      <section id="position-hero" className="relative h-screen">
        <Swiper
          modules={[FreeMode, Navigation, Autoplay]}
          loop={true}
          navigation={true}
          autoplay={{
            delay: 3000,
            disableOnInteraction: false,
          }}
          className="full-slider h-screen"
        >
          {hero_3_swiper_data.map((item) => {
            const { image, id, title, name, titleLink, userLink } = item;
            return (
              <SwiperSlide key={id}>
                <div className="swiper-slide after:bg-jacarta-900/60 after:absolute after:inset-0">
                  <Image
                    src={image}
                    className="absolute inset-0 h-full w-full object-cover"
                    alt={title}
                    layout="fill"
                  />
                </div>
              </SwiperSlide>
            );
          })}
        </Swiper>
        <div className="absolute center-in-parent">
          <h2 className="font-pixel slider-title font-semibold text-white text-center">
            Crypto Expo 2024            
          </h2>
          <TypewriterPlaces places={['Dubai', 'Atlantis', 'The Palm']} />
          <div className="pt-24">
            <CountdownTimer />
          </div>
        </div>
      </section>
    </>
  );
};

export default Hero_3;
