import React, { useState, useEffect } from 'react';
import dynamic from 'next/dynamic';
const Typewriter = dynamic(() => import('react-typewriter-effect'), { ssr: false });

const TypewriterPlaces = ({places}) => {
  
  return (
    <Typewriter
        textStyle={{
            color: '#126FF0DD',
            FontFamily: 'Press Start 2P',
            fontWeight: 500,
            fontSize: '3.5em',
            textAlign: 'center',
        }}
        startDelay={1000}
        cursorColor="#126FF0DD"
        multiText={places}
        multiTextDelay={1000}
        multiTextLoop={true} 
        typeSpeed={150}
        />
  );
};

export default TypewriterPlaces;