const hero_3_swiper_data = [
	{
		id: '1',
		image: '/images/hero/hero_slider_1.jpg',
		title: 'Crypto Expo 2024',
		name: 'Dubai',
		titleLink: '#',
		userLink: '#',
	},
	{
		id: '2',
		image: '/images/hero/hero_slider_2.jpg',
		title: 'Crypto Expo 2024',
		name: 'Atlatis',
		titleLink: '#',
		userLink: '#',
	},
	{
		id: '3',
		image: '/images/hero/hero_slider_3.jpg',
		title: 'Crypto Expo 2024',
		name: 'The Palm',
		titleLink: '#',
		userLink: '#',
	},
];

const topic_data = [
	{
		id: 1,
		title: "What WEB 3.0 means for enterprises?", 
		person: 9
	},
	{
		id: 2,
		title: "Value creation in the Metaverse", 
		person: 6
	},
	{
		id: 3,
		title: "The role of Stablecoins in a decentralized ecosystem", 
		person: 11
	},
	{
		id: 4,
		title: "DAOS: New Breed of Internet-Native Organizations", 
		person: 12
	},
	{
		id: 5,
		title: "Maximizing ESG impact with Blockchain", 
		person: 7
	},
	{
		id: 6,
		title: "Bridging DeFi and CeFi", 
		person: 3
	},
	{
		id: 7,
		title: "WEB 3 Gaming: The new frontier?", 
		person: 1
	},
	{
		id: 8,
		title: "Decrypting the regulatory landscape of virtual assets", 
		person: 2
	},
	{
		id: 9,
		title: "Central Bank Digital Currencies,and Cross-Border Payments", 
		person: 4
	},
	{
		id: 10,
		title: "WEB 3: The future is here and now", 
		person: 5
	},
	{
		id: 11,
		title: "NFT Market Overview: Trends & Opportunities", 
		person: 10
	},
	{
		id: 12,
		title: "Interoperability - Challenges and Opportunities", 
		person: 8
	}
];

const speaker_data = [
	{
		id: '1',
		image: '/images/speakers/Russell Armand.jpg',
		name: 'Russell Armand',
		company: 'Ledger Enterprise',
	},
	{
		id: '2',
		image: '/images/speakers/Danny Winn.jpg',
		name: 'Danny Winn',
		company: 'CoinMENA',
	},	
	{
		id: '3',
		image: '/images/speakers/Ilyas Vali.jpg',
		name: 'Ilyas Vali',
		company: 'Bitget',
	},
	{
		id: '4',
		image: '/images/speakers/Lex Sokolin.jpg',
		name: 'Lex Sokolin',
		company: 'Binance',
	},
	{
		id: '5',
		image: '/images/speakers/Pieter Vanhove.jpg',
		name: 'Pieter Vanhove',
		company: 'Fireblocks',
	},
	{
		id: '6',
		image: '/images/speakers/Adrian Zdunczyk.jpg',
		name: 'Adrian Zduńczyk',
		company: 'OneFootball Labs',
	},
	{
		id: '7',
		image: '/images/speakers/Daniel Antcliff.jpg',
		name: 'Daniel Antcliff',
		company: 'Lisk',
	},
	{
		id: '8',
		image: '/images/speakers/Delphine Forma.jpg',
		name: 'Delphine Forma',
		company: 'Dubai International Financial Centre Authority',
	},
	{
		id: '9',
		image: '/images/speakers/Fred Zhou.jpg',
		name: 'Fred Zhou',
		company: 'Alserkal Group',
	},
	{
		id: '10',
		image: '/images/speakers/Lennix Lai.jpg',
		name: 'Lennix Lai',
		company: 'OKX',
	},
	{
		id: '11',
		image: '/images/speakers/Victoria Scholar.jpg',
		name: 'The Palm',
		company: 'Ripple',
	},	
	{
		id: '12',
		image: '/images/speakers/Stephen Lindsay.jpg',
		name: 'Stephen Lindsay',
		company: 'Vechain',
	},
];

const sponsor_data= [
	{
		id: 1,
		image: '/images/sponsors/a16zCryptoResearch.png',
		link: 'https://a16zcrypto.com/',
	},
	{
		id: 2,
		image: '/images/sponsors/casper.png',
		link: 'https://casper.network/',
	},
	{
		id: 3,
		image: '/images/sponsors/iohk.png',
		link: 'https://iohk.io/',
	},
	{
		id: 4,
		image: '/images/sponsors/jpMorganChaseAndCo.png',
		link: 'https://www.jpmorganchase.com/',
	},
	{
		id: 5,
		image: '/images/sponsors/pqshield.png',
		link: 'https://pqshield.com/',
	},
	{
		id: 6,
		image: '/images/sponsors/ibm.png',
		link: 'https://www.ibm.com/',
	},
	{
		id: 7,
		image: '/images/sponsors/nttResearch.png',
		link: 'https://ntt-research.com/',
	},
	{
		id: 8,
		image: '/images/sponsors/hyperledgerFoundation.png',
		link: 'https://www.hyperledger.org/',
	},
];

const hero_3_swiper_thumbs_data = [
	{
		id: '0/images/hero/hero_slide_1_thumb.jpg',
		image: '/images/hero/hero_slide_1_thumb.jpg',
	},
	{
		id: '1/images/hero/hero_slide_2_thumb.jpg',
		image: '/images/hero/hero_slide_2_thumb.jpg',
	},
	{
		id: '2/images/hero/hero_slide_3_thumb.jpg',
		image: '/images/hero/hero_slide_3_thumb.jpg',
	},
	{
		id: '3/images/hero/hero_slide_4_thumb.jpg',
		image: '/images/hero/hero_slide_4_thumb.jpg',
	},
	{
		id: '4/images/hero/hero_slide_1_thumb.jpg',
		image: '/images/hero/hero_slide_1_thumb.jpg',
	},
	{
		id: '5/images/hero/hero_slide_4_thumb.jpg',
		image: '/images/hero/hero_slide_4_thumb.jpg',
	},
];

export { hero_3_swiper_data, topic_data, speaker_data, sponsor_data, hero_3_swiper_thumbs_data };
