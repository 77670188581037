import { useState, useEffect } from "react";
const CountdownTimer = () => {
  const zero = {
    weeks: 0,
    days: 0,
    hours: 0,
    mins: 0,
    secs: 0
  };
  const [diff, setDiff] = useState(zero);

  const expoDate = new Date(2024, 2, 15);

  useEffect(() => {
    setInterval(()=> {
      const differenceMilliseconds = expoDate.getTime() - new Date().getTime();
      // Convert milliseconds to seconds, minutes, hours, etc.
      const millisecondsInSecond = 1000;
      const millisecondsInMinute = millisecondsInSecond * 60;
      const millisecondsInHour = millisecondsInMinute * 60;
      const millisecondsInDay = millisecondsInHour * 24;
      const millisecondsInWeeks = millisecondsInDay * 7;

      // Calculate the differences in various units
      const weeksDifference = Math.floor(differenceMilliseconds / millisecondsInWeeks);
      const daysDifference = Math.floor((differenceMilliseconds % millisecondsInWeeks) / millisecondsInDay);
      const hoursDifference = Math.floor((differenceMilliseconds % millisecondsInDay) / millisecondsInHour);
      const minutesDifference = Math.floor((differenceMilliseconds % millisecondsInHour) / millisecondsInMinute);
      const secondsDifference = Math.floor((differenceMilliseconds % millisecondsInMinute) / millisecondsInSecond);
      
      setDiff({
        weeks: weeksDifference,
        days: daysDifference,
        hours: hoursDifference,
        mins: minutesDifference,
        secs: secondsDifference,
      })
    }, 1000); 
  }, [])

    

    return (
        <div className="countdown-wrap">
            {
              diff.weeks != 0 && (
                <div className="countdown-item">
                  <div className="countdown-number">{diff.weeks}</div>
                  <div className="countdown-text">weeks</div>
                </div>
              )
            }
            
            <div className="countdown-item">
              <div className="countdown-number">{String(diff.days).padStart(2, '0')}</div>
              <div className="countdown-text">days</div>
            </div>
            <div className="countdown-item">
            <div className="countdown-number">{String(diff.hours).padStart(2, '0')}</div>
              <div className="countdown-text">hours</div>
            </div>
            <div className="countdown-item">
              <div className="countdown-number">{String(diff.mins).padStart(2, '0')}</div>
              <div className="countdown-text">minutes</div>
            </div>
            <div className="countdown-item">
              <div className="countdown-number">{String(diff.secs).padStart(2, '0')}</div>
              <div className="countdown-text">seconds</div>
            </div>
        </div>
    );
};

export default CountdownTimer;